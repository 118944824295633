import BaseLogo from '../../Assets/images/wakandi-top-icon.svg';

export function BaseComponent({ title, children }) {
	return (
		<div className='p-component'>
			<div className="content-align-center-page">
				<div className="main-app-base mt-5">
					<div className="base-component">
						<div>
							<img src={BaseLogo} alt='wakandi logo' />
							<h3 className="main-title mt-4 mb-5">{title}</h3>
						</div>
					</div>
					<div className="main-content">
						{children}
					</div>
				</div>
			</div>
		</div>
	);
}